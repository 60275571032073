import * as React from "react";
import { graphql } from "gatsby";
import { Container, Segment, List } from "semantic-ui-react";
import { LayoutProps, withLayout } from "../components/Layout";
import { MarkdownRemark } from "../graphql-types";
import Title from "../components/Title";

interface EventPostProps extends LayoutProps {
  data: {
    post: MarkdownRemark;
  };
}

const EventPostPage: React.FC<EventPostProps> = (props) => {
  const { html } = props.data.post;
  const tags = props.data.post.frontmatter.tags
    .map((tag) => <List.Item key={tag} as="li">{tag}</List.Item>);

  return (
    <Container>
      <Title title="Events" />
      <Segment vertical
        style={{ border: "none" }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
      <List as="ul" celled horizontal>{tags}</List>
    </Container>
  );
};

export default withLayout(EventPostPage);

export const pageQuery = graphql`
query TemplateEventsPost($slug: String!) {
  post: markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    excerpt
    fields {
      slug
    }
    frontmatter {
      title
      tags
      date(formatString: "MMM D, YYYY")
    }
  }
}
`
